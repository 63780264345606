<template>
  <div class="accountList">
    <el-card class="mb-16">
      <div class="search-container">
      <el-form label-width="100px" size="small" label-position="right" class="migration-search" style="border:none;" inline>
          <!-- 员工账号/姓名/手机 -->
          <el-form-item class="inline" label="名称：">
          <el-input style="width:220px; margin-right:15px;" v-model="searchForm.nickname" placeholder="请输入姓名" clearable @keyup.native.enter="handleSearch" maxlength='17' />
          </el-form-item>
          <el-form-item class="inline" label="所在机构：">
          <el-input style="width:220px; margin-right:15px;" v-model="searchForm.enterpriseName" placeholder="请输入所在机构" clearable @keyup.native.enter="handleSearch" maxlength='17' />
          </el-form-item>
          <el-form-item class="inline" label="机构背景：">
            <el-select style="width:220px;" filterable  v-model="searchForm.institutionalBackgrounds" multiple collapse-tags placeholder="请选择机构背景" clearable @change="handleSearch">
              <el-option
                v-for="item in options[4]"
                :key="item.id"
                :label="item.labelName"
                :value="item.id">
              </el-option>
            </el-select>
          <!-- <el-input style="width:220px; margin-right:15px;" v-model="searchForm.institutionalBackgrounds" placeholder="请输入关注领域" clearable @keyup.native.enter="handleSearch" maxlength='17' /> -->
          </el-form-item>
          <el-form-item class="inline" label="关注领域：">
            <el-select style="width:220px;" filterable  v-model="searchForm.focusAreas" multiple collapse-tags placeholder="请选择关注领域" clearable @change="handleSearch">
              <el-option
                v-for="item in options[5]"
                :key="item.id"
                :label="item.labelName"
                :value="item.id">
              </el-option>
            </el-select>
          <!-- <el-input style="width:220px; margin-right:15px;" v-model="searchForm.focusAreas" placeholder="请输入关注领域" clearable @keyup.native.enter="handleSearch" maxlength='17' /> -->
          </el-form-item>
          <el-form-item class="inline" label="偏好伦次：">
            <el-select style="width:220px;" filterable  v-model="searchForm.preferenceRound" multiple collapse-tags placeholder="请选择偏好伦次" clearable @change="handleSearch">
              <el-option
                v-for="item in options[6]"
                :key="item.id"
                :label="item.labelName"
                :value="item.id">
              </el-option>
            </el-select>
          <!-- <el-input style="width:220px; margin-right:15px;" v-model="searchForm.preferenceRound" placeholder="请输入偏好伦次" clearable @keyup.native.enter="handleSearch" maxlength='17' /> -->
          </el-form-item>
          <!-- 员工账号/姓名/手机 -->
          <el-form-item class="inline" label="手机号：">
          <el-input style="width:220px; margin-right:15px;" v-model="searchForm.phone" placeholder="请输入手机号" clearable @keyup.native.enter="handleSearch" maxlength='17' />
          </el-form-item>
          <!-- 角色 -->
          <!-- <el-form-item class="inline" label="角色名称：">
          <el-input style="width:220px; margin-right:15px;" v-model="searchForm.roleName" placeholder="请输入角色名" clearable @keyup.native.enter="handleSearch" maxlength='17' />
          </el-form-item> -->
          <!-- <el-form-item class="inline" label="角色：">
          <el-select style="width:220px; margin-right:15px;" v-model="searchForm.role" placeholder="请选择角色" filterable clearable>
              <el-option v-for="i in roleList" :label="i.name" :value='i.id' :key="i.value"></el-option>
          </el-select>
          </el-form-item> -->
          <el-button type="primary" size="medium" @click="handleSearch" :disabled="isSearch">查询</el-button>
          <el-button size="medium" @click="handleReset" plain>重置</el-button>
      </el-form>
      </div>
    </el-card>
    <el-card class="mb-16">
      <div class="tabHeader" v-if="user.userType == 1">
        <p>共{{total}}条数据</p>
      </div>
      <myTable
      class="my-table"
      ref="myTable"
      :tableData="tableData"
      :tableColumns="tableColumns"
      tableBorder
      :page="pageInfo.pageNum"
      :rows="pageInfo.pageSize"
      :total="total"
      isPagination
      @handleChange="handleChange"
      @handleSelectionChange="handleSelectionChange"
      >
      <el-table-column slot="userType" label="角色" width="100px">
        <template slot-scope="{ row }">
          {{ row.userType == 1 ? '超级管理员' : row.userType == 2 ? '普通用户' : '副管理员' }}
        </template>
      </el-table-column>
      <el-table-column slot="userStatus" label="用户状态" width="100px">
        <template slot-scope="{ row }">
          {{ userStatusMap[row.userStatus] }}
        </template>
      </el-table-column>
      <el-table-column slot="operate" label="操作" fixed="right" width="220px">
          <template slot-scope="{ row }" v-if="user.inWhiteList || [1].includes(user.userType) || user.userExtend.includes('用户信息')">
            <el-button @click="() => handleDetail(row, operate.EDIT)" type="text">编辑</el-button>
            <el-button @click="() => handleDetail(row, operate.DISPLAY)" type="text" v-if="[1].includes(user.userType)">分配</el-button>
            <el-button @click="() => handleDetail(row, operate.EXAMINE)" type="text" v-if="[2].includes(row.userStatus) && [1].includes(user.userType)">
              审核
            </el-button>
            <el-button @click="() => handleDetail(row, operate.FREEZE)" type="text" v-if="[1, 4].includes(row.userStatus) && [1].includes(user.userType)">
              {{ row.userStatus == 1 ? '冻结' : '解冻' }}
            </el-button>
            <!-- <el-button @click="() => handleDetail(row, operate.DETAIL)" type="text">详情</el-button> -->
          </template>
      </el-table-column>
      </myTable>
    </el-card>
    <edit
      ref="edit"
      title="编辑"
      @confirm="getAllRoleList"/>
    <allocation
      ref="allocation"
      :title="`将'${activeData.nickname}'设置管理员`"
      @confirm="getAllRoleList"/>
  </div>
</template>

<script>
import  myTable  from "../../components/myTable.vue"
import allocation from './components/allocation.vue'
import edit from './components/edit.vue'
import { mapState } from 'vuex'
const userStatusMap = {
  1: '正常',
  2: '审核中',
  3: '审核不通过',
  4: '封禁'
}

export default {
  components: {
    myTable,
    allocation,
    edit
  },
  data() {
    return {
      userStatusMap,
      isSearch: false,
      searchForm: {
        nickname:'',
        phone:'',
        roleName:'',
        enterpriseName: '',
        institutionalBackgrounds: [],
        focusAreas: [],
        preferenceRound: []

      },
      activeData: {},
      roleList: [
        {
          id:11,
          name:'子管理员'
        },
        {
          id:22,
          name:'普通用户'
        },
      ],
      operate: {
        DETAIL: 1,
        EDIT: 2,
        DISPLAY: 3,
        FREEZE: 4,
        EXAMINE: 5
      },
      total: 2,
      tableData: [ ],
      tableColumns: [
        // {
        //   label: "用户ID",
        //   prop: "id",
        //   showTooltip: true,
        //   width: 200,
        // },
        {
          label: "姓名",
          prop: "nickname",
          showTooltip: true,
        },
        {
          label: "所在机构",
          prop: "enterpriseName",
          showTooltip: true,
          width: 200,
        },
        {
          label: "职位",
          prop: "position",
          width: 100,
          showTooltip: true,
        },
        {
          label: "机构背景",
          prop: "institutionalBackgroundsName",
          showTooltip: true,
          width: 200,
        },
        {
          label: "关注领域",
          prop: "focusAreasName",
          showTooltip: true,
          width: 200,
        },
        {
          label: "偏好伦次",
          prop: "preferenceRoundName",
          showTooltip: true,
          width: 200,
        },
        {
          label: "手机号",
          prop: "phone",
          showTooltip: true,
          width: 200,
        },
        {
          label: "用户状态",
          slot: "userStatus"
        },
        {
          label: "角色",
          slot: "userType"
        },
        {
          label: "操作",
          slot: "operate",
          width: "80px",
          fixed: true, 
          showTooltip: true,
        },
      ],
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
      },
      loading: false,
      options: {
        4: [],
        5: [],
        6: []
      }
    };
  },
  computed: {
    ...mapState(['user'])
  },
  mounted () {
    this.handleSearch()
    this.getAllRoleList()
    this.getAllLabels()
  },
  methods: {
    getAllRoleList() {
      const params = {
        // ...this.searchForm,
        ...this.pageInfo
      };
      this.$http.Post(this.$api.getUserList, params).then((res) => {
        this.total = Number(res.data.total);
        this.tableData = res.data.records;
      });
    },
    // 获取标签
    getAllLabels() {
      [4,5,6].forEach(labelGroupId => {
        this.$http.Get(this.$api.listByGroupId, { labelGroupId }).then(({ data = [] }) => {
          this.options[labelGroupId] = data
        })
      })
    },
    handleReset() {
      this.pageInfo.pageNum = 1
      this.searchForm = {
        nickname:'',
        phone:'',
        roleName:'',
        enterpriseName: '',
        institutionalBackgrounds: [],
        focusAreas: [],
        preferenceRound: []
      }
      this.handleSearch()
    },
    handleSearch() {
      this.pageInfo.pageNum = 1;
      this.getPageList();
    },
    getPageList() {
      const params = {
        ...this.searchForm,
        ...this.pageInfo
      };
      this.$http.Post(this.$api.userListByPage, params).then((res) => {
        this.total = Number(res.data.total);
        this.tableData = res.data.records;
      });
    },
    handleChange(val) {
      this.pageInfo = { ...val }
      this.getPageList()
    },
    // 表格选择
    handleSelectionChange(val) {
      console.log('表格选择', val)
    },
    handleDetail(row,type) {
      this.activeData = row
      const { userStatus } = row;
      switch(type){
        case this.operate.DETAIL:
          this.$router.push({
            path:'accountDetail',
            query:{userId:row.id}
          })
          break;
        case this.operate.EDIT:
          this.$refs.edit.init(row)
          // this.$message("编辑角色")
          break;
        case this.operate.DISPLAY:
          this.$refs.allocation.init(row)
          // this.$message("分配角色")
          break;
        case this.operate.FREEZE:
          this.$confirm(`${userStatus == 1 ? '冻结' : '解冻'}该账号！`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            beforeClose: async (action, instance, done) => {
              if (action === 'confirm') {
                instance.confirmButtonLoading = true;
                instance.confirmButtonText = '执行中...';
                await this.$http.Post(this.$api.adminUpdateUserStatus, { id: row.id, userStatus: userStatus == 1 ? 4 : 1 });
                done();
                instance.confirmButtonLoading = false;
                this.getAllRoleList()
              } else {
                done();
              }
            }
          })
          break;
        case this.operate.EXAMINE:
          this.$confirm(`审核该账号！`, '提示', {
            confirmButtonText: '通过',
            cancelButtonText: '审核不通过',
            distinguishCancelAndClose: true,
            type: 'warning',
            beforeClose: async (action, instance, done) => {
              console.log(action)
              if (action === 'confirm') {
                instance.confirmButtonLoading = true;
                instance.confirmButtonText = '执行中...';
                await this.$http.Post(this.$api.adminUpdateUserStatus, { id: row.id, userStatus: 1 });
                done();
                instance.confirmButtonLoading = false;
                // this.getAllRoleList()
              } else if (action === 'cancel') {
                instance.confirmButtonLoading = true;
                instance.confirmButtonText = '执行中...';
                await this.$http.Post(this.$api.adminUpdateUserStatus, { id: row.id, userStatus: 3 });
                done();
                instance.confirmButtonLoading = false;
                this.getAllRoleList()
              }
            }
          })
          break
      }
    }
  },
};
</script>
<style lang='less' scoped>
.title-box {
  display: flex;
  .title-right {
    margin-left: 10px;
    padding: 10px;
    .title {
      font-size: 18px;
      color: #333;
    }
    .content {
      font-size: 14px;
      color: #666;
    }
  }
  
}
.tabHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
  }
.mb-16 {
  margin-bottom: 16px;
}
</style>