<template>
  <el-select
    v-model="modelValue"
    multiple
    collapse-tags
    placeholder="请选择"
    v-bind="$attrs"
  >
    <el-option
      v-for="item in options"
      :key="item.id"
      :label="item.labelName"
      :value="item.id"
    >
    </el-option>
  </el-select>
</template>

<script>
// 无权限获取标签 1、行业标签  2、最近财年应收规模  3、最近财年利润规模 4.机构教育背景  5、关注领域 6、偏好轮次
export default{
    name: 'selectOptions',
    props: {
        labelGroupId: {
            type: Number,
            default: 1
        },
        value: {
            type:  Array,
            default: () => ([])
        }
    },
    data() {
        return {
            modelValue: this.value || [],
            options: []
        }
    },
    watch: {
        value: {
            handler(newVal) {
                this.modelValue = newVal;
            },
            immediate: true,
            deep: true
        },
        modelValue: {
            handler(newVal) {
                this.$emit('input', newVal);
                this.$emit('update:valueName', newVal.map(i => this.options.find(item => item.id == i).labelName).join())
                this.$emit('update:checkValue', newVal.map(i => this.options.find(item => item.id == i)))
            },
            immediate: true,
            deep: true
        }
    },
    // model: {
    //     prop: 'value',
    //     event: 'input'
    // },
    // computed: {
    //     modelValue: {
    //         get: () => {
    //             console.log(typeof this.value)
                
    //             if (this.value) {
    //                 console.log(typeof this.value)
    //                 return typeof this.value == 'object' ? this.value : [this.value]
    //                 // return this.value
    //             } else {
    //                 return []
    //             }
    //         },
    //         set: (value) => {
    //             this.$emit('input', value)
    //             return value
    //         }
    //     }
    // },
    
    mounted()  {
        this.getOptions()
    },
    methods: {
        getOptions() {
            const { labelGroupId } = this
            this.$http.Get(this.$api.insideListByGroupId, { labelGroupId }).then(({ data }) => {
                this.options = data
            })
        },
        // change() {
        //     this.$emit('input', this.modelValue)
        // }
    }
}
</script>