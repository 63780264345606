<template>
  <el-dialog
    :visible.sync="dialogVisible"
    @close="close"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div>
      <el-form ref="form" :model="form" label-width="80px">
        {{ form.userType }}
        <el-form-item label="账号类型:" prop="userTypeParam" v-if="user.userType == 1 && form.userTypeParam !== 1">
          <el-switch
            v-model="form.userTypeParam"
            :active-value="3"
            :inactive-value="2"
            active-text="副管理员"
            inactive-text="普通账号">
          </el-switch>
        </el-form-item>
        <el-form-item label="行业:" prop="industry">
          <!-- 
              v-model="form.industry"
           -->
          <selectOptions
              :labelGroupId="1"
              :multiple-limit="0"
              v-model="form.userPermissionScopeList"
              :checkValue.sync="userPermissionScopeValue"
          />
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="confirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { baseRequestUrl } from "@/public/api.js";
import selectOptions from "@/components/selectOptions/index.vue";
import { mapState } from "vuex";
export default {
  name: "allocation",
  data() {
    return {
      baseRequestUrl,
      dialogVisible: false,
      loading: false,
      id: "",
      // 后端是一个数组json，组件封装是数组id
      userPermissionScopeValue: [],
      form: {
        userTypeParam: 2,
        userPermissionScopeList: ''
      },
    };
  },
  components: {
    selectOptions,
  },
  computed: {
    ...mapState(["token", 'user']),
  },
  mounted() {
    console.log(this.$api.hotList);
  },
  methods: {
    init(data) {
      this.id = data.id;
      Object.keys(this.form).forEach((key) => {
        this.$set(this.form, key, data[key]);
        if (key === 'userTypeParam') {
          this.$set(this.form, 'userTypeParam', data.userType)
        }
        if (key === 'userPermissionScopeList') {
          this.userPermissionScopeValue = data.userPermissionScopeList || []
          this.$set(this.form, key, (data.userPermissionScopeList || []).map(i => i.id))
        }
      });
      this.dialogVisible = true;
    },
    confirm() {
      this.loading = true;
      this.$http
        .Post(this.$api.appointAsAdminAssistant, {
          id: this.id,
          userTypeParam: this.form.userTypeParam,
          userPermissionScopeList: this.userPermissionScopeValue.map(i => ({id: i.id, name: i.labelName}))
        })
        .then(({ msg, code }) => {
          this.$message.success(msg);
          if (code === 200) {
            this.dialogVisible = false;
            this.$emit("confirm");
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    close() {},
  },
};
</script>

<style lang="less" scoped>
/deep/.avatar-uploader {
  .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
  }
  .avatar {
    width: 100px;
    height: 100px;
    display: block;
  }
}
/deep/ .avatar {
  width: 200px;
  height: 200px;
}
</style>