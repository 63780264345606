<template>
    <el-dialog
      :visible.sync="dialogVisible"
      @close="close"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <div>
        <el-form ref="form" :model="form" label-width="80px" :disabled="![1].includes(user.userType)">
          <el-form-item label="头像:" prop="avatar">
            <el-upload
              class="avatar-uploader"
              :action="baseRequestUrl + $api.uploadImage"
              :headers="{
                Token: token,
              }"
              :show-file-list="false"
              :limit="1"
              accept="image/*"
              :on-success="res => handleAvatarSuccess(res, 'avatar')"
            >
              <img
                v-if="this.form.avatar"
                :src="this.form.avatar"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="机构背景:" prop="institutionalBackgrounds">
            <selectOptions
              :multiple-limit="2"
              :labelGroupId="4"
              v-model="form.institutionalBackgrounds"
              :valueName.sync="form.institutionalBackgroundsName"
            ></selectOptions>
          </el-form-item>
          <el-form-item label="关注领域:" prop="focusAreas">
            <selectOptions
              :multiple-limit="2"
              :labelGroupId="5"
              v-model="form.focusAreas"
              :valueName.sync="form.focusAreasName"
            ></selectOptions>
          </el-form-item>
          <el-form-item label="偏好轮次:" prop="preferenceRound">
            <selectOptions
              :multiple-limit="2"
              :labelGroupId="6"
              v-model="form.preferenceRound"
              :valueName.sync="form.preferenceRoundName"
            ></selectOptions>
          </el-form-item>
          <el-form-item label="职位:" prop="position">
            <el-input style="width: 223px" v-model="form.position" placeholder="请输入职位"></el-input>
          </el-form-item>
          <el-form-item label="姓名:" prop="nickname">
            <el-input style="width: 223px" v-model="form.nickname"></el-input>
          </el-form-item>
          <el-form-item label="名片:" prop="avatar">
            <el-upload
              class="avatar-uploader"
              :action="baseRequestUrl + $api.uploadImage"
              :headers="{
                Token: token,
              }"
              :show-file-list="false"
              :limit="1"
              accept="image/*"
              :on-success="res => handleAvatarSuccess(res, 'businessCard')"
            >
              <template v-if="form.businessCard">
                <div class="upload-box">
                  <img
                    :src="this.form.businessCard"
                    class="avatar"
                  />
                  <div class="icon">
                    <span>
                      <i class="el-icon-zoom-in" @click.stop="preview"></i>
                      <i class="el-icon-upload2"></i>
                    </span>
                  </div>
                </div>
              </template>
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="用户权限:" prop="userExtend">
            <el-select v-model="form.userExtend" placeholder="请选择" multiple collapse-tags>
              <el-option
                v-for="(item, index) in userExtendOptions"
                :key="index"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <el-dialog append-to-body :visible.sync="previewDialog">
          <img width="100%" :src="this.form.businessCard" alt="">
        </el-dialog>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </span>
    </el-dialog>
  </template>
  
  <script>
  import { baseRequestUrl } from "@/public/api.js";
  import selectOptions from "@/components/selectOptions/index.vue";
  import { mapState } from "vuex";
  export default {
    name: "edit",
    data() {
      return {
        baseRequestUrl,
        dialogVisible: false,
        previewDialog: false,
        loading: false,
        id: "",
        userExtendOptions: [
          '用户信息',
          '用户列表'
        ],
        form: {
          // 头像
          avatar: "",
          // 名片
          businessCard: "",
          // 企业id
          // enterpriseId: '',
          // enterpriseName: '',
          // 机构背景
          institutionalBackgrounds: "",
          institutionalBackgroundsName: "",
          // 关注领域
          focusAreas: "",
          focusAreasName: "",
          nickname: "",
          // 偏好轮次
          preferenceRound: "",
          preferenceRoundName: "",
          // 职位
          position: '',
          userExtend: []
        },
      };
    },
    components: {
      selectOptions,
    },
    computed: {
      ...mapState(["token", 'user']),
    },
    mounted() {
      console.log(this.$api.hotList);
    },
    methods: {
      preview() {
        this.previewDialog = true
      },
      init(data) {
        this.id = data.id;
        Object.keys(this.form).forEach((key) => {
          console.log(key)
          if (key == 'userExtend') {
            console.log(data[key])
            return this.$set(this.form, key, data[key] ? data[key].split(',') : []);
          }
          this.$set(this.form, key, data[key]);
        });
        this.dialogVisible = true;
      },
      handleAvatarSuccess({ data: { fileUrl = "" } = {} }, key) {
        this.form[key] = fileUrl;
      },
      confirm() {
        if (![1].includes(this.user.userType)) return this.dialogVisible = false
        this.loading = true;
        this.$http
          .Post(this.$api.adminModify, {
            id: this.id,
            ...this.form,
            userExtend: this.form.userExtend?.toString()
          })
          .then(({ msg, code }) => {
            this.$message.success(msg);
            if (code === 200) {
              this.dialogVisible = false;
              this.$emit("confirm");
            }
          })
          .finally(() => {
            this.loading = false;
          });
      },
      close() {},
    },
  };
  </script>
  
  <style lang="less" scoped>
  /deep/.avatar-uploader {
    .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      .upload-box{
        position: relative;
        .icon{
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0);
          left: 0;
          top: 0;
          transition: all .2s;
          display: flex;
          justify-content: center;
          align-items: center;
          span{
            font-size: 16px;
            opacity: 0;
            i{
              margin: 0 10px;
            }
          }
          &:hover{
            background-color: rgba(0, 0, 0, .3);
            color: #fff;
            span{
              opacity: 1;
            }
          }
        }
      }
    }
    .el-upload:hover {
      border-color: #409eff;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 100px;
      height: 100px;
      line-height: 100px;
      text-align: center;
    }
    .avatar {
      width: 100px;
      height: 100px;
      display: block;
    }
  }
  /deep/ .avatar {
    width: 200px;
    height: 200px;
  }
  </style>